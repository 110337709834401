@import '../../styles/customMediaQueries.css';

.root {}

.pageRoot {
  display: unset !important;
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }

  & main {
    display: unset !important;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 30px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }

  @media(max-width: 1024px) {
    flex-direction: column;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 360px);
    flex-basis: calc(100% - 360px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: unset;
    width: min(calc(var(--contentMaxWidth) + 72px), 100vw);
    padding: 64px 36px 0 36px;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
  }

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }

  @media(max-width: 768px) {
    padding: 10px 24px;
  }

  &:after {
    content: "";
    bottom: -18px;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    background-color: rgb(216, 220, 225);

    @media (--viewportMedium) {
      bottom: -20px;
    }
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;
  font-size: 18px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 22px;
    line-height: 30px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
    font-size: 22px;
    line-height: 30px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;
  font-size: 16px;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media(max-width: 500px) {
    font-size: 14px;
  }

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  font-weight: var(--fontWeightRegular);
  font-size: 16px;

  @media(max-width: 500px) {
    font-size: 14px;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;
  position: relative;

  @media(max-width: 768px) {
    padding: 10px 24px;
  }

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }

  &:after {
    content: "";
    bottom: -18px;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    background-color: rgb(216, 220, 225);

    @media (--viewportMedium) {
      bottom: -20px;
    }
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;
  position: relative;

  @media(max-width: 768px) {
    padding: 10px 24px;
  }

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }

  &:after {
    content: "";
    bottom: -18px;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    background-color: rgb(216, 220, 225);

    @media (--viewportMedium) {
      bottom: -20px;
    }
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;
  position: relative;

  @media(max-width: 768px) {
    padding: 10px 24px;
  }

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }

  @media (--viewportLarge) {
    padding: 8px 0;
  }

  &:after {
    content: "";
    bottom: -18px;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    background-color: rgb(216, 220, 225);

    @media (--viewportMedium) {
      bottom: -20px;
    }
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }

  @media(max-width: 460px) {
    gap: 15px;
    padding: 5px 0;
  }
}

.detailValue {
  line-height: normal;
  text-align: right;

  @media(max-width: 500px) {
    font-size: 14px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryModal {
  z-index: 99999999;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;
  min-height: 100%;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }

  @media(max-width: 768px) {
    max-width: 100%;
    width: 100%;
    height: 350px;
    max-height: 350px;
  }

  &>div {
    @media(max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }

    & img {
      @media(max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }

  @media(max-width: 768px) {
    margin: 0 24px 10px 24px;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.locationSection {
  display: flex;
  align-items: center;
  margin: 5px 0 20px;

  @media(max-width: 768px) {
    margin: 0 0 10px;
    padding: 0 24px;
  }

  & .locationAddress {
    font-size: 16px;
    margin-left: 10px;

    @media(max-width: 500px) {
      font-size: 14px;
    }
  }

  & .locationIcon {
    & svg {
      width: 20px;
      height: 20px;

      & path {
        fill: var(--marketplaceColor);
      }
    }
  }
}

.topSectionBar {
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(216, 220, 225);

  @media(max-width: 768px) {
    padding-bottom: 20px;
  }
}

.cancelPolicyWrapper {
  margin-bottom: 36px;
  padding: 0 24px;
  position: relative;

  @media(--viewportMedium) {
    margin-bottom: 40px;
    padding: 0;
  }

  @media(max-width: 768px) {
    padding: 10px 24px;
  }

  &:after {
    content: "";
    bottom: -18px;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    background-color: rgb(216, 220, 225);

    @media (--viewportMedium) {
      bottom: -20px;
    }
  }

  & .cancelHeading {
    margin-bottom: 10px;
    font-size: 18px;

    @media(--viewportMedium) {
      font-size: 22px;
      line-height: 30px;
    }

    & .cancelPolicyHeading {
      font-weight: var(--fontWeightBold);
      letter-spacing: -0.09px;
      /* margin-left: 10px; */
    }
  }

  & .cancelDescription {
    font-weight: var(--fontWeightRegular);
    font-size: 16px;

    @media(max-width: 500px) {
      font-size: 14px;
    }
  }
}

.howItWorkWrapper {
  margin-bottom: 36px;
  padding: 0 24px;
  position: relative;

  @media(max-width: 768px) {
    padding: 10px 24px;
  }

  @media(--viewportMedium) {
    margin-bottom: 40px;
    padding: 0;
  }

  &:after {
    content: "";
    bottom: -18px;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    background-color: rgb(216, 220, 225);

    @media (--viewportMedium) {
      bottom: -20px;
    }
  }

  & .howItWorkHeading {
    font-weight: var(--fontWeightBold);
    font-size: 18px;

    @media(--viewportMedium) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  & .workGrid {
    display: flex;
    flex-direction: column;

    & .colSection {
      margin-top: 20px;

      & .workImage {
        margin-bottom: 10px;

        & svg {
          width: 40px;
          height: 40px;
        }
      }

      & .headingName {
        font-weight: var(--fontWeightRegular);
        font-size: 16px;

        @media(max-width: 500px) {
          font-size: 14px;
        }
      }
    }
  }
}

.listingCard {
  width: 100%;
  min-width: 100%;
}

.similarListingCard {
  @media (--viewportMedium) {
    /* width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative; */
  }

  @media (--viewportLarge) {
    /* max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px; */
  }

  @media (--viewportXLarge) {
    /* max-width: var(--contentMaxWidth);
    padding: 0; */
  }

  &>div {
    /* width: 100%; */
  }

}

.similarHeading {
  padding: 24px;

  @media(--viewportMedium) {
    padding: 0;
  }

  & .similarTitle {
    color: var(--colorGrey700);
    font-size: 18px;

    @media(--viewportMedium) {
      font-size: 22px;
      line-height: 30px;
    }
  }
}

.withoutSlider {
  /* background-color: blue; */
  gap: 20px;
  display: flex;
  overflow-x: auto;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #aca8a8;
  }

  &>div {
    width: 33%;

    @media(max-width: 768px) {
      width: 55%;
    }

    @media(max-width: 500px) {
      width: 80%;
    }
  }
}

.similarCardGrid {
  &>div {

    &>div {

      &:nth-child(2) {
        &>div {
          &>div {
            &>div {}
          }
        }
      }
    }
  }
}

.similarCardSlider {

  /* display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  } */
  &>div {

    & :global(.slick-disabled) {
      opacity: .5;
      background-color: #6c757d !important;

      & svg {
        & path {
          fill: #ffffff !important;
        }
      }
    }

    &>div {


      &:nth-child(1) {
        cursor: pointer;
        width: 40px;
        /* z-index: 1; */
        /* color: #ff5a00; */
        border-radius: 40px;
        height: 40px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
        left: -34px;

        &:hover {
          background-color: #00ac9d;

          & svg {

            & path {
              fill: #fff;
            }
          }
        }

        & svg {
          width: 23px;

          & path {
            fill: #00ac9d;
          }
        }

        &::before {
          display: none;
        }
      }

      &:nth-child(2) {
        &>div {
          &>div {
            &>div {
              margin: 0 10px 10px;
              display: flex;
            }
          }
        }
      }

      &:nth-child(3) {
        cursor: pointer;
        width: 40px;
        /* z-index: 1; */
        /* color: #ff5a00; */
        border-radius: 40px;
        height: 40px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, .2);

        &:hover {
          background-color: #00ac9d;

          & svg {

            & path {
              fill: #fff;
            }
          }
        }

        & svg {
          width: 23px;

          & path {
            fill: #00ac9d;
          }
        }

        &::before {
          display: none;
        }
      }
    }



    /* padding: 3px; */

    /* &>div {
      padding: 3px;

      &:nth-child(2) {
        cursor: pointer;
        width: 40px;
        z-index: 5;
        color: #ff5a00;
        background-color: transparent;
        border-radius: 40px;
        height: 40px;
        background-color: #fff;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
        font-size: 22px;
        left: 3px;

        &::after {
          width: 20px;
          height: 20px;
          background-size: cover;
          background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20width%3D%2225px%22%20height%3D%2225px%22%20viewBox%3D%22-2.453%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eleft%3C%2Ftitle%3E%3Cpath%20d%3D%22m8.031%202%201.063%201.063%20-5.094%205.125%205.094%205.125%20-1.063%201.063L1.906%208.188%208.031%202Z%22%20fill%3D%22%2300ac9d%22%2F%3E%3C%2Fsvg%3E");
        }

        &:hover {
          background-color: var(--marketplaceColor);

          &::after {
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20width%3D%2225px%22%20height%3D%2225px%22%20viewBox%3D%22-2.453%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eleft%3C%2Ftitle%3E%3Cpath%20d%3D%22m8.031%202%201.063%201.063%20-5.094%205.125%205.094%205.125%20-1.063%201.063L1.906%208.188%208.031%202Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
          }
        }


      }

      &:nth-child(3) {
        cursor: pointer;
        width: 40px;
        z-index: 5;
        color: #ff5a00;
        background-color: transparent;
        border-radius: 40px;
        height: 40px;
        background-color: #fff;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
        font-size: 22px;
        right: 0;

        &::after {
          width: 20px;
          height: 20px;
          background-size: cover;
          background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20width%3D%2225px%22%20height%3D%2225px%22%20viewBox%3D%22-2.406%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eright%3C%2Ftitle%3E%3Cpath%20d%3D%22m3.063%2014.375%20-1.063%20-1.063%205.094%20-5.125L2%203.063l1.063%20-1.063%206.125%206.188L3.063%2014.375Z%22%20fill%3D%22%2300ac9d%22%2F%3E%3C%2Fsvg%3E");
        }

        &:hover {
          background-color: var(--marketplaceColor);

          &::after {
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20width%3D%2225px%22%20height%3D%2225px%22%20viewBox%3D%22-2.406%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eright%3C%2Ftitle%3E%3Cpath%20d%3D%22m3.063%2014.375%20-1.063%20-1.063%205.094%20-5.125L2%203.063l1.063%20-1.063%206.125%206.188L3.063%2014.375Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
          }
        }
      }
    } */

  }

  /* & :global(.swiper) {
    width: 100%;
    height: 100%;
  }

  & :global(.swiper-button-disabled) {
    display: none;
  } */
}

.socialShareWrapper {
  display: none;

  @media(--viewportLarge) {
    display: block;
    margin-bottom: 40px;
  }

  & .socialShare {
    &>div {
      padding: 10px;
      cursor: pointer;
      font-weight: 700;
    }
  }
}

.socialShare {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;

  @media(max-width: 460px) {
    gap: 10px;
  }
}

.keepButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  width: 50%;
  padding: 5px 15px;
  border-radius: 4px;

  @media(max-width: 460px) {
    font-size: 12px;
  }

  & svg {
    & path {
      /* stroke: var(--marketplaceColor); */
    }
  }

  & .dislike {
    & svg {
      & path {
        stroke: var(--marketplaceColor);
      }
    }
  }
}

.shareButton {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid rgb(216, 220, 225);
  width: 50%;
  border-radius: 4px;
  padding: 5px 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);

  @media(max-width: 460px) {
    font-size: 12px;
  }

  & svg {
    & path {
      fill: rgba(0, 0, 0, 0.6);
    }
  }
}

.sharingBox {
  display: flex;
  flex-direction: column;
  align-items: center;

  & h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 0px;
    margin-bottom: 25px;
    text-align: center;
    position: relative;
    width: 100%;
    padding-bottom: 25px;

    &:after {
      content: "";
      background-color: #0000001f;
      position: absolute;
      bottom: 0;
      left: -60px;
      right: -60px;
      height: 1px;
      width: 126%;
    }
  }

  & .shareButton {
    background-color: #00ac9d;
    color: #fff;
    width: 70%;
    margin-bottom: 20px;

    & svg {
      & path {
        fill: #fff;
      }
    }
  }
}